.home_wallet_container {
  border-radius: 40px;
  background-color: black;
  width: 70%;
  /* height: 200px; */
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: white !important;
}

.align_left {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: right;
  /* align-items: center; */
}

.fl_right {
  /* float: right; */
  font-size: 4rem;
}

.text_green {
  color: #51d27f;
  margin: 0px;
  text-align: left;
}

.align_wallet_info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.wallet_icon {
  width: 52px !important;
  height: 52px !important;
}

.eye_icon {
  width: 18px !important;
  height: 15px !important;
  margin-left: auto;
  top: 0;
}

.align_wallet_info h2 {
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 2.5rem;
  color: white !important;
}

.small_text {
  font-size: 1.1rem;
  font-weight: medium;
}

.hide_on_tab {
  display: block;
}

.bonus_container {
  background-color: white;
  border-radius: 15px;
  padding: 10px 20px;
  color: black !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.bonus_container h2 {
  margin: 0px;
  font-size: 16px;
  font-family: "Gilroy Bold";
  font-weight: bold;
}

.align_link_right {
  margin-left: auto;
  text-decoration: underline;
  cursor: pointer;
  font-family: "Gilroy Bold";
  font-size: 3rem;
}

.wallet_right_content,
.join_konnect_text {
  font-size: 3.5rem;
  color: rgba(0, 0, 0, 0.85);
  font-family: "Gilroy Bold";
  margin-top: 2rem;
}

.wallet_right_content {
  text-align: right;
}

.home_banner5 {
  background-image: url("../images/homepage/home_banner4.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e9faf5;
  width: 100%;
  overflow: hidden;
  padding: 50px 0px;
}

.home_banner3 {
  background-image: url("../images/homepage/banner3.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e9faf5;
  width: 100%;
  overflow: hidden;
  padding: 50px 0;
  /* margin-top: 3%; */
  /* margin-bottom: 3%; */
}

.owl-carousel .owl-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home_item {
  padding: 10px;
  border-radius: 20px;
  flex: 0 0 auto;
}

.home_item img {
  height: 250px;
  /* Uniform height for all images */
  width: auto;
  /* Dynamic width based on image dimensions */
  object-fit: cover;
  display: block;
}

.hero_wallet_blackhead {
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  color: white;
  border-radius: 8px;
  /* width: 60%; */
}

.hero_wallet_blackhead.wallet--info {
  align-items: normal;
  padding: 10px 15px;
  width: 50%;
}

.hero_wallet_blackhead img {
  width: 80%;
  height: auto;
  cursor: pointer;
}

/* .home_hero .grid_flex {
    display: flex;
    justify-content: space-between;
} */

.wallet--moreInfo {
  display: block;
  width: 50%;
  justify-content: left;
}

.wallet--moreInfo p {
  font-size: 1.5rem;
  margin-top: 4px;
}

.banner5Modal .ant-modal-close,
.partnerModal .ant-modal-close,
.appModal .ant-modal-close,
.underConstructionModal .ant-modal-close,
.restaurantModal .ant-modal-close {
  display: block !important;
  outline: none;
}

.banner5Modal,
.partnerModal,
.underConstructionModal,
.restaurantModal {
  z-index: 1;
}

.banner5Modal h2 {
  color: #44843f;
  font-size: 3rem;
  margin-top: 10px;
}

.restaurantModal {
  /* width: 100% !important; */
  min-width: 43vh !important;
  max-width: 80vw !important;
  overflow: hidden !important;
}

.restaurantModal .ant-modal-content {
  width: 100%;
  min-width: 43vh !important;
  overflow: hidden !important;
}

.restaurantModal .ant-modal-body {
  width: 100% !important;
  height: 350px !important;
  overflow: hidden !important;
}

.restaurantModal.edit .ant-modal-body {
  height: unset !important;
}

.restaurantModal .ant-modal-body {
  padding: 20px;
  background: #f5fff5;
  /* Mint Cream */
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  width: 100% !important;
}

.restaurantModal.edit .ant-modal-close {
  display: none !important;
}

.restaurantModal.edit .ant-modal-body {
  background: #fff !important;
  font-family: "Work Sans", serif;
}

.restaurantModal.edit .ant-modal-body .ant-select-selector {
  background: unset !important;
  padding: 0px 10px !important;
  border: unset !important;
}

.ant-select-item.ant-select-item-option {
  padding: 15px 12px !important;
}

.restaurantModal.edit .ant-select-selector {
  align-items: center !important;
}

@media (max-width: 369px) {
  .restaurantModal .ant-modal-content {
    min-width: 20vh !important;
    width: 100% !important;
  }
}


.numbered-list {
  list-style-type: decimal;
  padding-left: 20px;
}

.numbered-list li {
  margin-bottom: 10px;
}

.text_bold {
  font-weight: 600;
}

.link-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
}

.icon {
  animation: moveRight 2s infinite alternate;
  margin-right: 8px;
  font-size: 18px;
  color: #44843f;
}

.align_link_right {
  margin: 0;
}

/* Keyframes for the animation */
@keyframes moveRight {
  0% {
    transform: translateX(0);
    /* Starting position */
  }

  100% {
    transform: translateX(8px);
    /* Final position */
  }
}

.homeBanner,
.home_banner2_hero .home_banner3_hero {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #e9faf5;
  width: 100%;
  overflow: hidden;
}

.homeBanner {
  background-image: url("../images/homepage/banner3.png");
}

.home_banner2_hero {
  /* background-image: url('../images/homepage/home_bg.png'); */
  background-color: #d3ecdc;
}

.home_banner3_hero {
  background-image: url("../images/homepage/home_bg2.png");
  padding: 50px 0px;
}

.home_banner3_hero .center_content {
  padding: 0 2%;
  margin: 0px auto;
}

.text_h3 {
  /* font-size: 3.5rem; */
  color: rgba(0, 0, 0, 0.85);
  font-family: "Gilroy Bold";
}



.appModal .ant-modal-body {
  background: #f5fff5;
  border-radius: 10px;
}

.appModal h2 {
  display: flex;
  align-items: center;
  gap: 5px;
}

.appModal p span {
  margin-right: 8px;
}

.partnerModal .ant-modal-body {
  padding: 20px;
  background: #f5fff5;
  /* Mint Cream */
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  width: 100% !important;
}

/* .partnerModal.ant-modal {
  min-width: 100vh !important;
}

.partnerModal.ant-modal-content {
  min-width: 100vh !important;
} */



/* to write above */

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  /* Responsive columns based on width */
  grid-auto-rows: auto;
  /* Auto rows based on content */
  gap: 10px;
  /* Gap between grid items */
  grid-auto-flow: dense;
  /* Densely pack the grid */
  margin: 20px;
}

/* Grid item style */
.grid-item {
  background-color: #4a90e2;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-item img {
  width: 100%;
  height: auto;
  /* Preserve aspect ratio of images */
  object-fit: cover;
}

.home_hero {
  height: 100vh !important;
  min-height: 100vh;
  max-height: 100vh;
}

/* Desktop: Maintain 2 rows, adjust the number of columns based on the image size */
@media (min-width: 601px) {
  .grid-container {
    grid-template-rows: repeat(2, auto);
    /* Keep 2 rows */
  }
}

/* Mobile layout: Keep 2 rows, but hide some images if needed */
@media (max-width: 600px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    /* Smaller items on mobile */
    grid-template-rows: repeat(2, auto);
    /* Keep 2 rows */
  }

  /* Hide extra items on mobile after filling 2 rows */
  .grid-item:nth-child(n + 5) {
    display: none;
  }
}

@media screen and (min-width: 720px) and (max-width: 1024px) {
  .align_left {
    width: 100%;
  }

  /* .hide_on_tab {
    display: none;
  } */

  .home_wallet_container {
    width: 100%;
    height: 200px;
  }
}

@media screen and (max-width: 720px) {
  .home_hero {
    height: 100% !important;
  }

  .wallet_right_content,
  .join_konnect_text {
    text-align: left;
    font-size: 3rem;
  }

  .text_h3 {
    text-align: center;
  }

  .fl_right {
    text-align: center;
    font-size: 3rem;
    color: white;
  }

  .hide_on_tab {
    display: block;
  }

  .home_wallet_container {
    width: 100%;
    height: 200px;
  }

  .align_left {
    width: 100%;
    justify-content: center;
  }

  .hero_wallet_blackhead {
    width: 100%;
    padding: 10px 15px;
    align-items: normal;
  }

  .hero_wallet_blackhead.wallet--info {
    align-items: normal;
    padding: 10px 15px;
    width: 100%;
  }

  .home_hero .grid_flex {
    display: block;
    width: 100%;
  }

  .wallet--moreInfo {
    width: 100%;
  }

  .home_banner3,
  .homeBanner {
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
      url("../images/homepage/banner3.png");
  }
}